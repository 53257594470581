import { useRecoilState } from 'recoil';
import Profile from '../Profile';
import { accountSelector } from '@stories/account';
import React, { useCallback, useEffect, useState } from 'react';
import { apiRoute, requestSecureGet, requestSecurePatch, requestSecurePost } from '@libs/api';
import { AccountResponseTypes } from '@typedef/Account/account.types';
import { EAccountRole } from '@typedef/Account/account-role.enum';
import { OrderNumberTypes } from '@typedef/Company/company.types';
import { inputNumberOnly } from '@libs/inputValidator';

const ProfileContainer = () => {
  const [account, setAccount] = useRecoilState(accountSelector);

  const { orderNumberAutoGeneration, orderNumberPrefixValue, orderNumberIntValue } = account.company;

  const [changeProfile, setChangeProfile] = useState<boolean>(false);
  const [userName, setUserName] = useState('');
  const [orderNumberData, setOrderNumberData] = useState<OrderNumberTypes>({
    orderNumberIntValue,
    orderNumberPrefixValue,
    orderNumberAutoGeneration,
  });
  const [changeOrderNumberData, setChangeOrderNumberData] = useState<boolean>(false);

  const byteToMegabyte = useCallback((bytes: number) => {
    return Math.round(bytes / (1024 * 1024));
  }, []);

  const onClickUpdateProfile = useCallback(async () => {
    const { config } = await requestSecurePatch(
      apiRoute.account.update,
      {},
      {
        userName: userName,
      },
      account.accessToken,
    );

    if (config.status === 200) {
      setChangeProfile(false);
      setAccount({
        ...account,
        userName: userName,
      });
    }
  }, [account, userName]);

  const updateOrderNumber = useCallback(async () => {
    const { orderNumberAutoGeneration, orderNumberPrefixValue, orderNumberIntValue } = orderNumberData;

    const payload = {
      orderNumberAutoGeneration,
      ...(orderNumberAutoGeneration && {
        orderNumberPrefixValue,
        orderNumberIntValue,
      }),
    };

    const { config } = await requestSecurePatch(
      `${apiRoute.company.updateOrderNumber}${account.company.id}`,
      {},
      payload,
      account.accessToken,
    );

    if (config.status === 200) {
      setChangeOrderNumberData(false);
      setAccount((prev) => ({
        ...prev,
        company: { ...prev.company, ...payload },
      }));
    }
  }, [account, orderNumberData]);

  const loadUserSelf = useCallback(async () => {
    const { config, data } = await requestSecureGet<AccountResponseTypes>(
      apiRoute.account.getSelf,
      {},
      account.accessToken,
    );

    if (config.status === 200) {
      window.localStorage.setItem('id', data.id);
      window.localStorage.setItem('userId', data.userId);
      window.localStorage.setItem('userName', data.userName);
      window.localStorage.setItem('role', data.role);
      window.localStorage.setItem('company', JSON.stringify(data.company));
      window.localStorage.setItem('createdAt', data.createdAt);
      window.localStorage.setItem('updatedAt', data.updatedAt);

      setAccount({ ...data, accessToken: account.accessToken, role: EAccountRole.valueOf(data.role)! });
      setUserName(data.userName);
    }
  }, []);

  const fileFlush = useCallback(async () => {
    const { config } = await requestSecurePost(apiRoute.company.fileFlush, {}, {}, account.accessToken);

    if (config.status !== 200) return alert('파일 정리에 실패했습니다.');
  }, []);

  const handleChange = useCallback(
    (name: string) => {
      setUserName(name);

      if (account.userName === name) {
        setChangeProfile(false);
      } else {
        setChangeProfile(true);
      }
    },
    [account],
  );

  const handleOrderNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let changedValue: boolean | string;

    switch (e.target.name) {
      case 'orderNumberAutoGeneration':
        changedValue = e.target.checked;
        break;
      case 'orderNumberIntValue':
        changedValue = inputNumberOnly(e.target.value);
        break;
      default:
        changedValue = e.target.value;
    }

    setOrderNumberData((prevState) => ({
      ...prevState,
      [e.target.name]: changedValue,
    }));
  }, []);

  useEffect(() => {
    setUserName(account.userName);
    loadUserSelf();
  }, []);

  useEffect(() => {
    setChangeOrderNumberData(
      !Object.entries({ orderNumberAutoGeneration, orderNumberPrefixValue, orderNumberIntValue }).every(
        ([key, value]) => orderNumberData[key as keyof OrderNumberTypes] === value,
      ),
    );
  }, [orderNumberData]);

  return (
    <Profile
      company={account.company}
      account={account}
      userName={userName}
      handleChange={handleChange}
      byteToMegabyte={byteToMegabyte}
      changeProfile={changeProfile}
      onClickUpdateProfile={onClickUpdateProfile}
      fileFlush={fileFlush}
      orderNumberData={orderNumberData}
      handleOrderNumberChange={handleOrderNumberChange}
      changeOrderNumberData={changeOrderNumberData}
      updateOrderNumber={updateOrderNumber}
    />
  );
};

export default ProfileContainer;
