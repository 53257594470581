import '../styles/CustomStyle.scss';
import ReactDropdown, { Option } from 'react-dropdown';
import { useCallback, useEffect, useState } from 'react';
import { WeatherTypes } from '@typedef/Widget/weather.types';
import { WidgetTypes } from '@typedef/Widget/widget.types';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectedLayout: WidgetTypes | null;
  weatherList: WeatherTypes[];
};

const CustomWeather = ({ handleOnChange, selectedLayout, weatherList }: Props) => {
  const [location1, setLocation1] = useState<string[]>([]);
  const [location2, setLocation2] = useState<string[]>([]);
  const [location3, setLocation3] = useState<string[]>([]);

  const [selectLocation, setSelectLocation] = useState<(string | undefined)[]>(['', '', '']);

  const handleLocationChanged = useCallback(
    async (type: string, value: string) => {
      if (type === 'location1') {
        setSelectLocation([value, '', '']);
        handleOnChange('location1', value);
        handleOnChange('location2', '');
        handleOnChange('location3', '');

        setLocation2(
          weatherList
            .filter((data) => data.location1 === value && data.location2.length !== 0 && data.location3 === '')
            .map((data) => data.location2),
        );
        setLocation3([]);
      } else if (type === 'location2') {
        setSelectLocation([selectLocation[0], value, '']);
        handleOnChange('location1', selectLocation[0]);
        handleOnChange('location2', value);
        handleOnChange('location3', '');

        setLocation3(
          weatherList
            .filter(
              (data) => data.location1 === selectLocation[0] && data.location2 === value && data.location3.length !== 0,
            )
            .map((data) => data.location3),
        );
      } else if (type === 'location3') {
        setSelectLocation([selectLocation[0], selectLocation[1], value]);
        handleOnChange('location1', selectLocation[0]);
        handleOnChange('location2', selectLocation[1]);
        handleOnChange('location3', value);
      }
      handleOnChange('weatherId', '');
    },
    [selectLocation, handleOnChange, weatherList],
  );

  useEffect(() => {
    if (weatherList.length < 1) return;

    setLocation1(
      weatherList
        .filter((weather) => weather.location2 === '' && weather.location3 === '')
        .map((data) => data.location1),
    );

    if (!selectedLayout) {
      return;
    }

    const weather = weatherList.find((data) => data.id === selectedLayout.weatherId);
    if (!weather) return;

    setSelectLocation([weather.location1, weather.location2, weather.location3]);

    if (weather.location2) {
      setLocation2(
        weatherList
          .filter(
            (data) => data.location1 === weather.location1 && data.location2.length !== 0 && data.location3 === '',
          )
          .map((data) => data.location2),
      );
    }
    if (weather.location3) {
      setLocation3(
        weatherList
          .filter(
            (data) =>
              data.location1 === weather.location1 &&
              data.location2 === weather.location2 &&
              data.location3.length !== 0,
          )
          .map((data) => data.location3),
      );
    }
  }, [weatherList]);

  return (
    <div className="custom">
      <div className="weather-custom">
        <div className="container">
          <p className="title">광역시/도</p>
          <ReactDropdown
            options={location1}
            className={'weather-dropdown'}
            value={selectLocation[0]}
            onChange={(value: Option) => {
              handleLocationChanged('location1', value.value);
            }}
          />
        </div>
        <div className="container">
          <p className="title">시/군/구</p>
          <ReactDropdown
            options={location2}
            className={'weather-dropdown'}
            value={selectLocation[1]}
            onChange={(value: Option) => {
              handleLocationChanged('location2', value.value);
            }}
          />
        </div>
        <div className="container">
          <p className="title">읍/면/동</p>
          <ReactDropdown
            options={location3}
            className={'weather-dropdown'}
            value={selectLocation[2]}
            onChange={(value: Option) => {
              handleLocationChanged('location3', value.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomWeather;
