import styled from 'styled-components';

export const ProfileContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 70vh;

    ${({ theme }) => theme.flex.col}
    gap: 30px;

    .my-info {
        ${({ theme }) => theme.flex.col}
        gap: 20px;

        .profile-data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            position: relative;

            & > p {
                font-size: 18px;

                &:first-child {
                    font-weight: 700;

                    background: linear-gradient(to top, #1ec3ff66 20%, transparent 10%);
                }
            }
        }

        .btns {
            & > button {
                border: 0;
                border-radius: 6px;

                padding: 10px 16px;

                color: #fff;
                background-color: #15b9f5;

                font-size: 17px;
                font-weight: 600;
            }
        }
    }

    & > div,
    & > article > section {
        border-radius: 7px;

        padding: 30px;

        background-color: #fff;

        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;

        input {
            border: 1px solid #dfdfdf;
            border-radius: 6px;

            padding: 8px;
        }

        .title-headline {
            width: max-content;

            margin-bottom: 20px;

            ${({ theme }) => theme.flex.row}
            align-items: center;
            gap: 8px;

            position: relative;

            & > svg {
                width: 24px;
                height: 24px;

                position: relative;
                z-index: 1;
            }

            & > p {
                font-size: 22px;
                font-weight: 600;

                position: relative;
                z-index: 1;
            }

            .highlighter {
                width: 100%;
                height: 16px;

                background-color: rgba(21, 185, 245, 0.4);

                position: absolute;
                top: 12px;
                z-index: 0;
            }
        }

        & > div:not(.title-headline):not(.my-info) {
            ${({ theme }) => theme.flex.col}
            gap: 20px;

            .file-flush {
                & > button {
                    border: 0;
                    border-radius: 6px;

                    padding: 10px 16px;

                    color: #fff;
                    background-color: #ffa800;

                    font-size: 17px;
                    font-weight: 600;
                }
            }

            .save-order-number {
                & > button {
                    border: 0;
                    border-radius: 6px;

                    padding: 10px 16px;

                    color: #fff;
                    background-color: #5cce10;

                    font-size: 17px;
                    font-weight: 600;
                }
            }

            .profile-data {
                ${({ theme }) => theme.flex.row}
                align-items: center;
                gap: 10px;

                position: relative;

                & > p {
                    font-size: 18px;

                    &:first-child {
                        font-weight: 700;
                    }
                }
            }

            .profile-data:hover .visible-data {
                display: flex;
            }

            .visible-data {
                border: 1px solid #ffe1a4;
                border-radius: 6px;

                padding: 6px;

                display: none;
                flex-direction: column;
                gap: 8px;

                background-color: #fff5e0;

                position: absolute;
                top: 24px;

                .profile-data {
                    & > p {
                        font-size: 14px;
                    }

                    & > p:first-child {
                        width: 80px;
                    }
                }
            }
        }
    }

    & > article {
        width: 100%;

        ${({ theme }) => theme.flex.row};
        gap: 30px;

        & > section {
            width: 100%;

            ${({ theme }) => theme.flex.col}
            gap: 10px;

            &:first-child {
                .title-headline {
                    .highlighter {
                        background-color: rgba(255, 168, 0, 0.4);
                    }
                }

                & > div {
                    & > .profile-data {
                        & > p:first-child {
                            background: linear-gradient(to top, #ffaa0066 20%, transparent 10%);
                        }
                    }
                }
            }

            &:last-child {
                .title-headline {
                    .highlighter {
                        background-color: rgba(93, 206, 17, 0.4);
                    }
                }

                & > div {
                    .profile-data {
                        & > p:first-child {
                            background: linear-gradient(to top, #62cb1b66 20%, transparent 10%);
                        }
                    }
                }
            }
        }
    }
`;

export const ToggleSwitch = styled.label<{ checkedColor?: string; checkedBorderColor?: string; }>`
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    cursor: pointer;
    margin-left: 10px;

    & input[type='checkbox'] {
        display: none;
    }

    .toggle-switch-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ddd;
        border-radius: 20px;
        box-shadow: inset 0 0 0 2px #ccc;
        transition: background-color 0.3s ease-in-out;
    }

    .toggle-switch-handle {
        position: absolute;
        top: 2.5px;
        left: 3px;
        width: 15px;
        height: 15px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease-in-out;
    }

    &::before {
        content: '';
        position: absolute;
        top: -25px;
        right: -18px;
        font-size: 12px;
        font-weight: bold;
        color: #aaa;
        text-shadow: 1px 1px #fff;
        transition: color 0.3s ease-in-out;
    }

    & input[type='checkbox']:checked + .toggle-switch-handle {
        transform: translateX(15px);
    }

    & input[type='checkbox']:checked + .toggle-switch-background {
        /* background-color: #15b9f5;
        box-shadow: inset 0 0 0 2px #1198ca; */

        background-color: ${({ checkedColor }) => checkedColor ?? '#8fdc5c'};
        box-shadow: ${({ checkedColor }) => `inset 0 0 0 2px ${checkedColor ?? '#8fdc5c'}`};
    }

    & input[type='checkbox']:checked + &:before {
        content: 'On';
        right: -15px;
    }

    & input[type='checkbox']:checked + .toggle-switch-background .toggle-switch-handle {
        transform: translateX(18px);
    }
`;
